.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background {
  background-image: url('../public/images/Psychadelic_BG_Dark_72dpi.jpg');
  background-repeat: repeat; /* Repeats the image */
  background-size: auto; /* Adjusts the size */
  background-position: top left; /* Starts at the top-left corner */
}

.banner {
  color: white;
  background-color: #351e00;
  border-top: 1px solid #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
  padding: 20px 0;
}

.btn-red {
  background-color: #ee1f27;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 10px; /* Add horizontal space between buttons */
}

.btn-red:hover {
  background-color: darkred;
}

.footer {
  color: white;
  background-color: #351e00;
  padding: 20px 0;
}


